@charset "UTF-8";
html {
  font-size: 16px;
  color: #212529;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  box-sizing: border-box; }

* {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*-webkit-touch-callout:none; 禁用ios长按菜单*/ }

blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
legend,
figcaption,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td {
  text-align: center;
  vertical-align: middle; }

fieldset,
iframe,
img {
  border: 0; }

audio,
embed,
img,
object,
video {
  height: auto;
  max-width: 100%; }

img {
  vertical-align: middle; }

a img, img {
  image-rendering: optimizeQuality; }

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: 400; }

ol,
ul {
  list-style: none; }

caption {
  text-align: left; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400; }

q:after,
q:before {
  content: ""; }

abbr,
acronym {
  border: 0; }

a {
  color: #0894ec;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a:active {
  color: #0a8ddf; }

a, input, textarea, select, button {
  outline: 0; }

button,
input,
textarea {
  appearance: none;
  color: #3d4145; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  appearance: none; }

input[type=datetime-local] {
  appearance: none;
  outline: none;
  border: none; }

select {
  color: #3d4145; }

.fs-12 {
  font-size: 12px; }

.fs-14 {
  font-size: 14px; }

.fs-16 {
  font-size: 16px; }

.fs-18 {
  font-size: 18px; }

.fs-20 {
  font-size: 20px; }

.fs-22 {
  font-size: 22px; }

.fs-24 {
  font-size: 24px; }

.fs-26 {
  font-size: 26px; }

.fs-28 {
  font-size: 28px; }

.fs-30 {
  font-size: 30px; }

.fs-32 {
  font-size: 32px; }

.fs-34 {
  font-size: 34px; }

.fs-36 {
  font-size: 36px; }

.fs-48 {
  font-size: 48px; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.block {
  display: block; }

.inline {
  display: inline-block; }

.overflow-hide {
  overflow: hidden; }

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clearfix {
  zoom: 1; }

.disappear {
  visibility: hidden; }

.appear {
  visibility: visible !important;
  opacity: 1; }

.rel-position {
  position: relative; }

.relative {
  position: relative; }

.txt-nowrap {
  white-space: nowrap; }

.radius {
  border-radius: 10px; }

.vertical {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0; }

.horizantal {
  margin: 0 auto; }

.center,
.horizantal {
  position: absolute;
  left: 0;
  right: 0; }

.center {
  top: 0;
  bottom: 0;
  margin: auto; }

.margin-center {
  margin: 0 auto; }

.txt-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.border-default {
  border: 1px solid #e7e7e7; }

.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-border {
  border: 0 !important; }

.bg-white {
  background: #fff !important; }

.bg-primary {
  background: #0894ec !important; }

.bg-success {
  background: #4cd964 !important; }

.bg-danger {
  background: #f6383a !important; }

.bg-warning {
  background: #f60 !important; }

.bg-black {
  background: #000000; }

.inline-vertical {
  vertical-align: middle; }

.full {
  width: 100%;
  height: 100%; }

.dis-table {
  display: table;
  table-layout: fixed;
  word-break: break-all; }

.table-cell {
  display: table-cell; }

.dis-table .table-cell {
  vertical-align: top; }

.box-shadow {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19); }

.modal-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10600;
  visibility: hidden;
  opacity: 0;
  transition-duration: 400ms;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .modal-shadow.visible {
    visibility: visible;
    opacity: 1; }
  .modal-shadow.modal-content-top {
    align-items: flex-start;
    align-content: flex-start; }
  .modal-shadow.modal-content-down {
    align-items: flex-end;
    align-content: flex-end; }
  .modal-shadow.modal-content-center {
    align-items: center;
    align-content: center; }

.pointer {
  cursor: pointer; }

.lh-1 {
  line-height: 1; }

.lh-1_5 {
  line-height: 1.5; }

.lh-2_ {
  line-height: 2; }

.lh-2_5 {
  line-height: 2.5; }

.width-full {
  width: 100% !important; }

.width-half {
  width: 50% !important; }

.height-full {
  height: 100% !important; }

.height-half {
  height: 50% !important; }

.z-index-1 {
  z-index: 1; }

.z-index-2 {
  z-index: 2; }

.z-index-3 {
  z-index: 3; }

.z-index-99 {
  z-index: 99; }

.horizantal-scroll {
  overflow: hidden;
  overflow-x: auto;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 500;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-type: proximity;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  -ms-overflow-style: none; }
  .horizantal-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none; }

.down-line:after {
  content: '';
  position: absolute;
  background-color: #d7d5d8;
  display: block !important;
  z-index: 15;
  left: 0;
  bottom: 0;
  right: auto;
  top: auto;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .down-line:after {
      transform: scaleY(0.5); } }
  @media only screen and (-webkit-min-device-pixel-ratio: 3) {
    .down-line:after {
      transform: scaleY(0.33); } }

/*专属*/
.animate-zoomOut {
  animation: zoomOut .3s ease-out forwards; }

.animate-zoomIn {
  animation: zoomIn .3s ease-out forwards; }

@keyframes zoomOut {
  0% {
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    transform: scale3d(1, 1, 1); } }

@keyframes zoomIn {
  0% {
    transform: scale3d(1, 1, 1); }
  100% {
    transform: scale3d(0.3, 0.3, 0.3); } }

.notify-scroll {
  transform: translateZ(0) translateX(100%);
  animation: notify-scroll 15s linear 1s infinite;
  white-space: nowrap; }

@keyframes notify-scroll {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-100%); } }

.transition-slide-up {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 100%, 0);
  transition: transform .5s; }
  .transition-slide-up.active {
    transform: translate3d(0, 0, 0); }

.transition-slide-down {
  transform: translate3d(0, -100%, 0);
  transition: transform .5s; }
  .transition-slide-down.active {
    transform: translate3d(0, 0, 0); }

@font-face {
  font-family: "iconfont";
  src: url("//at.alicdn.com/t/font_847404_xf99f7k1wb.eot?t=1547633730223");
  /* IE9*/
  src: url("//at.alicdn.com/t/font_847404_xf99f7k1wb.eot?t=1547633730223#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/font_847404_xf99f7k1wb.woff?t=1547633730223") format("woff"), url("//at.alicdn.com/t/font_847404_xf99f7k1wb.ttf?t=1547633730223") format("truetype"), url("//at.alicdn.com/t/font_847404_xf99f7k1wb.svg?t=1547633730223#iconfont") format("svg");
  /* iOS 4.1- */ }

.icon {
  font-family: "iconfont" !important;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  background-size: 100% auto;
  background-position: center;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale; }

.icon-checkbox:before {
  content: "\e8b9"; }

.icon-checkbox-fill:before {
  content: "\e8ba"; }

.icon-radio:before {
  content: "\e8bb"; }

.icon-radio-fill:before {
  content: "\e8bc"; }

.icon-more-horizontal:before {
  content: "\e8c4"; }

.icon-more-vertical:before {
  content: "\e8c5"; }

.icon-like-circle-fill:before {
  content: "\e8d8"; }

.icon-unlike-circle-fill:before {
  content: "\e8d9"; }

.icon-xiaoxi:before {
  content: "\e62f"; }

.icon-check-circle:before {
  content: "\e77d"; }

.icon-Dollar:before {
  content: "\e77e"; }

.icon-close-circle:before {
  content: "\e77f"; }

.icon-frown:before {
  content: "\e780"; }

.icon-meh:before {
  content: "\e781"; }

.icon-plus-circle:before {
  content: "\e799"; }

.icon-play-circle:before {
  content: "\e782"; }

.icon-question-circle:before {
  content: "\e783"; }

.icon-smile:before {
  content: "\e784"; }

.icon-time-circle:before {
  content: "\e785"; }

.icon-timeout:before {
  content: "\e786"; }

.icon-earth:before {
  content: "\e787"; }

.icon-YUAN:before {
  content: "\e788"; }

.icon-warning-circle:before {
  content: "\e789"; }

.icon-sync:before {
  content: "\e78a"; }

.icon-redo:before {
  content: "\e78b"; }

.icon-reload:before {
  content: "\e78c"; }

.icon-message:before {
  content: "\e78d"; }

.icon-poweroff:before {
  content: "\e78e"; }

.icon-logout:before {
  content: "\e78f"; }

.icon-login:before {
  content: "\e790"; }

.icon-setting:before {
  content: "\e791"; }

.icon-eye:before {
  content: "\e792"; }

.icon-location:before {
  content: "\e793"; }

.icon-edit-square:before {
  content: "\e794"; }

.icon-export:before {
  content: "\e795"; }

.icon-save:before {
  content: "\e796"; }

.icon-Import:before {
  content: "\e797"; }

.icon-detail:before {
  content: "\e798"; }

.icon-plus-square:before {
  content: "\e79a"; }

.icon-adduser:before {
  content: "\e7ae"; }

.icon-deleteteam:before {
  content: "\e7af"; }

.icon-deleteuser:before {
  content: "\e7b0"; }

.icon-addteam:before {
  content: "\e7b1"; }

.icon-user:before {
  content: "\e7b2"; }

.icon-team:before {
  content: "\e7b3"; }

.icon-areachart:before {
  content: "\e7b4"; }

.icon-redenvelope:before {
  content: "\e7b5"; }

.icon-file:before {
  content: "\e7bb"; }

.icon-safetycertificate:before {
  content: "\e7c0"; }

.icon-alert:before {
  content: "\e7c3"; }

.icon-delete:before {
  content: "\e7c4"; }

.icon-hourglass:before {
  content: "\e7c5"; }

.icon-experiment:before {
  content: "\e7c6"; }

.icon-bell:before {
  content: "\e7c7"; }

.icon-trophy:before {
  content: "\e7c8"; }

.icon-USB:before {
  content: "\e7c9"; }

.icon-skin:before {
  content: "\e7ca"; }

.icon-home:before {
  content: "\e7cb"; }

.icon-like:before {
  content: "\e7cc"; }

.icon-unlike:before {
  content: "\e7cd"; }

.icon-unlock:before {
  content: "\e7ce"; }

.icon-lock:before {
  content: "\e7cf"; }

.icon-customerservice:before {
  content: "\e7d0"; }

.icon-flag:before {
  content: "\e7d1"; }

.icon-shop:before {
  content: "\e7d2"; }

.icon-rocket:before {
  content: "\e7d3"; }

.icon-shopping:before {
  content: "\e7d4"; }

.icon-contacts:before {
  content: "\e7d5"; }

.icon-scan:before {
  content: "\e7d6"; }

.icon-sliders:before {
  content: "\e7d7"; }

.icon-laptop:before {
  content: "\e7d8"; }

.icon-barcode:before {
  content: "\e7d9"; }

.icon-camera:before {
  content: "\e7da"; }

.icon-car:before {
  content: "\e7db"; }

.icon-read:before {
  content: "\e7dc"; }

.icon-cloud-upload:before {
  content: "\e7dd"; }

.icon-cloud:before {
  content: "\e7de"; }

.icon-cloud-download:before {
  content: "\e7df"; }

.icon-video:before {
  content: "\e7e0"; }

.icon-notification:before {
  content: "\e7e1"; }

.icon-sound:before {
  content: "\e7e2"; }

.icon-qrcode:before {
  content: "\e7e3"; }

.icon-image:before {
  content: "\e7e4"; }

.icon-mail:before {
  content: "\e7e5"; }

.icon-idcard:before {
  content: "\e7e6"; }

.icon-heart:before {
  content: "\e7e7"; }

.icon-error:before {
  content: "\e7e8"; }

.icon-star:before {
  content: "\e7e9"; }

.icon-wifi:before {
  content: "\e7ea"; }

.icon-edit:before {
  content: "\e7eb"; }

.icon-key:before {
  content: "\e7ec"; }

.icon-disconnect:before {
  content: "\e7ed"; }

.icon-link:before {
  content: "\e7ee"; }

.icon-man:before {
  content: "\e7ef"; }

.icon-percentage:before {
  content: "\e7f0"; }

.icon-search:before {
  content: "\e7f1"; }

.icon-phone:before {
  content: "\e7f2"; }

.icon-shake:before {
  content: "\e7f3"; }

.icon-tag:before {
  content: "\e7f4"; }

.icon-wrench:before {
  content: "\e7f5"; }

.icon-woman:before {
  content: "\e7f6"; }

.icon-scissor:before {
  content: "\e7f7"; }

.icon-right:before {
  content: "\e7f8"; }

.icon-left:before {
  content: "\e7f9"; }

.icon-up:before {
  content: "\e7fa"; }

.icon-down:before {
  content: "\e7fb"; }

.icon-fullscreen:before {
  content: "\e7fc"; }

.icon-fullscreen-exit:before {
  content: "\e7fd"; }

.icon-doubleleft:before {
  content: "\e7fe"; }

.icon-doubleright:before {
  content: "\e7ff"; }

.icon-arrowright:before {
  content: "\e800"; }

.icon-arrowup:before {
  content: "\e801"; }

.icon-arrowleft:before {
  content: "\e802"; }

.icon-arrowdown:before {
  content: "\e803"; }

.icon-upload:before {
  content: "\e804"; }

.icon-download:before {
  content: "\e805"; }

.icon-sort-descending:before {
  content: "\e806"; }

.icon-sort-ascending:before {
  content: "\e807"; }

.icon-swap:before {
  content: "\e808"; }

.icon-rise:before {
  content: "\e809"; }

.icon-unorderedlist:before {
  content: "\e80a"; }

.icon-check:before {
  content: "\e80b"; }

.icon-ellipsis:before {
  content: "\e80c"; }

.icon-close:before {
  content: "\e80e"; }

.icon-question:before {
  content: "\e80f"; }

.icon-plus:before {
  content: "\e813"; }

.icon-rollback:before {
  content: "\e810"; }

.icon-pause:before {
  content: "\e812"; }

.icon-desktop:before {
  content: "\e842"; }

.icon-gift:before {
  content: "\e843"; }

.icon-stop:before {
  content: "\e844"; }

.icon-fire:before {
  content: "\e845"; }

.icon-thunderbolt:before {
  content: "\e846"; }

.icon-check-circle-fill:before {
  content: "\e847"; }

.icon-close-circle-fill:before {
  content: "\e848"; }

.icon-info-circle-fill:before {
  content: "\e849"; }

.icon-plus-circle-fill:before {
  content: "\e857"; }

.icon-question-circle-fill:before {
  content: "\e84a"; }

.icon-frown-fill:before {
  content: "\e84b"; }

.icon-Dollar-circle-fill:before {
  content: "\e84c"; }

.icon-poweroff-circle-fill:before {
  content: "\e84d"; }

.icon-meh-fill:before {
  content: "\e84e"; }

.icon-play-circle-fill:before {
  content: "\e84f"; }

.icon-smile-fill:before {
  content: "\e850"; }

.icon-warning-circle-fill:before {
  content: "\e851"; }

.icon-time-circle-fill:before {
  content: "\e852"; }

.icon-YUAN-circle-fill:before {
  content: "\e853"; }

.icon-heart-fill:before {
  content: "\e854"; }

.icon-piechart-circle-fil:before {
  content: "\e855"; }

.icon-message-fill:before {
  content: "\e856"; }

.icon-image-fill:before {
  content: "\e860"; }

.icon-idcard-fill:before {
  content: "\e861"; }

.icon-read-fill:before {
  content: "\e862"; }

.icon-contacts-fill:before {
  content: "\e863"; }

.icon-delete-fill:before {
  content: "\e864"; }

.icon-notification-fill:before {
  content: "\e865"; }

.icon-flag-fill:before {
  content: "\e866"; }

.icon-shopping-fill:before {
  content: "\e867"; }

.icon-skin-fill:before {
  content: "\e868"; }

.icon-video-fill:before {
  content: "\e869"; }

.icon-sound-fill:before {
  content: "\e86a"; }

.icon-bulb-fill:before {
  content: "\e86b"; }

.icon-bell-fill:before {
  content: "\e86c"; }

.icon-fire-fill:before {
  content: "\e86d"; }

.icon-gift-fill:before {
  content: "\e86e"; }

.icon-hourglass-fill:before {
  content: "\e86f"; }

.icon-home-fill:before {
  content: "\e870"; }

.icon-trophy-fill:before {
  content: "\e871"; }

.icon-location-fill:before {
  content: "\e872"; }

.icon-cloud-fill:before {
  content: "\e873"; }

.icon-customerservice-fill:before {
  content: "\e874"; }

.icon-eye-fill:before {
  content: "\e875"; }

.icon-like-fill:before {
  content: "\e876"; }

.icon-lock-fill:before {
  content: "\e877"; }

.icon-unlike-fill:before {
  content: "\e878"; }

.icon-star-fill:before {
  content: "\e879"; }

.icon-unlock-fill:before {
  content: "\e87a"; }

.icon-alert-fill:before {
  content: "\e87b"; }

.icon-phone-fill:before {
  content: "\e87c"; }

.icon-edit-fill:before {
  content: "\e87d"; }

.icon-thunderbolt-fill:before {
  content: "\e87e"; }

.icon-wrench-fill:before {
  content: "\e87f"; }

.icon-camera-fill:before {
  content: "\e880"; }

.icon-mail-fill:before {
  content: "\e881"; }

.icon-setting-fill:before {
  content: "\e882"; }

.icon-sliders-fill:before {
  content: "\e883"; }

.icon-alibaba:before {
  content: "\e884"; }

.icon-alipay:before {
  content: "\e888"; }

.icon-taobao:before {
  content: "\e889"; }

.icon-zhihu:before {
  content: "\e88a"; }

.icon-linkedin:before {
  content: "\e88c"; }

.icon-facebook:before {
  content: "\e88d"; }

.icon-chrome:before {
  content: "\e88e"; }

.icon-apple:before {
  content: "\e890"; }

.icon-android:before {
  content: "\e891"; }

.icon-instagram:before {
  content: "\e892"; }

.icon-windows:before {
  content: "\e893"; }

.icon-Youtube:before {
  content: "\e894"; }

.icon-Gitlab-fill:before {
  content: "\e895"; }

.icon-dingtalk:before {
  content: "\e896"; }

.icon-android-fill:before {
  content: "\e897"; }

.icon-apple-fill:before {
  content: "\e898"; }

.icon-windows-fill:before {
  content: "\e89a"; }

.icon-QQ:before {
  content: "\e89b"; }

.icon-twitter:before {
  content: "\e89c"; }

.icon-weibo:before {
  content: "\e89d"; }

.icon-Youtube-fill:before {
  content: "\e89e"; }

.icon-wechat-fill:before {
  content: "\e89f"; }

.icon-chrome-fill:before {
  content: "\e8a0"; }

.icon-alipay-circle-fill:before {
  content: "\e8a1"; }

.icon-github-fill:before {
  content: "\e8a3"; }

.icon-QQ-circle-fill:before {
  content: "\e8a4"; }

.icon-twitter-circle-fill:before {
  content: "\e8a7"; }

.icon-taobao-circle-fill:before {
  content: "\e8a8"; }

.icon-weibo-circle-fill:before {
  content: "\e8a9"; }

.icon-zhihu-circle-fill:before {
  content: "\e8aa"; }

.icon-alipay-square-fill:before {
  content: "\e8ab"; }

.icon-facebook-fill:before {
  content: "\e8ac"; }

.icon-instagram-fill:before {
  content: "\e8ad"; }

.icon-linkedin-fill:before {
  content: "\e8ae"; }

.icon-QQ-square-fill:before {
  content: "\e8af"; }

.icon-twitter-square-fill:before {
  content: "\e8b0"; }

.icon-taobao-square-fill:before {
  content: "\e8b1"; }

.icon-weibo-square-fill:before {
  content: "\e8b2"; }

.icon-zhihu-square-fill:before {
  content: "\e8b3"; }

.icon-zoomout:before {
  content: "\e8b4"; }

.icon-audio:before {
  content: "\e8b5"; }

.icon-audio-fill:before {
  content: "\e8b6"; }

.icon-robot:before {
  content: "\e8b7"; }

.icon-zoomin:before {
  content: "\e8b8"; }

.row {
  box-sizing: border-box;
  overflow: hidden; }
  .row *[class*='col-']:last-child {
    margin-right: 0 !important; }
  .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12, .row .col-13, .row .col-14, .row .col-15, .row .col-16, .row .col-17, .row .col-18, .row .col-19, .row .col-20, .row .col-21, .row .col-22, .row .col-23, .row .col-24 {
    min-height: 1px;
    position: relative;
    float: left;
    box-sizing: border-box;
    transition: all 0.3s ease-in; }
  .row .col-1 {
    width: 4.16667%; }
  .row .col-2 {
    width: 8.33333%; }
  .row .col-3 {
    width: 12.5%; }
  .row .col-4 {
    width: 16.66667%; }
  .row .col-5 {
    width: 20.83333%; }
  .row .col-6 {
    width: 25%; }
  .row .col-7 {
    width: 29.16667%; }
  .row .col-8 {
    width: 33.33333%; }
  .row .col-9 {
    width: 37.5%; }
  .row .col-10 {
    width: 41.66667%; }
  .row .col-11 {
    width: 45.83333%; }
  .row .col-12 {
    width: 50%; }
  .row .col-13 {
    width: 54.16667%; }
  .row .col-14 {
    width: 58.33333%; }
  .row .col-15 {
    width: 62.5%; }
  .row .col-16 {
    width: 66.66667%; }
  .row .col-17 {
    width: 70.83333%; }
  .row .col-18 {
    width: 75%; }
  .row .col-19 {
    width: 79.16667%; }
  .row .col-20 {
    width: 83.33333%; }
  .row .col-21 {
    width: 87.5%; }
  .row .col-22 {
    width: 91.66667%; }
  .row .col-23 {
    width: 95.83333%; }
  .row .col-24 {
    width: 100%; }
  .row .col-pull-1 {
    right: 4.16667%; }
  .row .col-pull-2 {
    right: 8.33333%; }
  .row .col-pull-3 {
    right: 12.5%; }
  .row .col-pull-4 {
    right: 16.66667%; }
  .row .col-pull-5 {
    right: 20.83333%; }
  .row .col-pull-6 {
    right: 25%; }
  .row .col-pull-7 {
    right: 29.16667%; }
  .row .col-pull-8 {
    right: 33.33333%; }
  .row .col-pull-9 {
    right: 37.5%; }
  .row .col-pull-10 {
    right: 41.66667%; }
  .row .col-pull-11 {
    right: 45.83333%; }
  .row .col-pull-12 {
    right: 50%; }
  .row .col-pull-13 {
    right: 54.16667%; }
  .row .col-pull-14 {
    right: 58.33333%; }
  .row .col-pull-15 {
    right: 62.5%; }
  .row .col-pull-16 {
    right: 66.66667%; }
  .row .col-pull-17 {
    right: 70.83333%; }
  .row .col-pull-18 {
    right: 75%; }
  .row .col-pull-19 {
    right: 79.16667%; }
  .row .col-pull-20 {
    right: 83.33333%; }
  .row .col-pull-21 {
    right: 87.5%; }
  .row .col-pull-22 {
    right: 91.66667%; }
  .row .col-pull-23 {
    right: 95.83333%; }
  .row .col-pull-24 {
    right: 100%; }
  .row .col-pull-0 {
    right: auto; }
  .row .col-push-1 {
    left: 4.16667%; }
  .row .col-push-2 {
    left: 8.33333%; }
  .row .col-push-3 {
    left: 12.5%; }
  .row .col-push-4 {
    left: 16.66667%; }
  .row .col-push-5 {
    left: 20.83333%; }
  .row .col-push-6 {
    left: 25%; }
  .row .col-push-7 {
    left: 29.16667%; }
  .row .col-push-8 {
    left: 33.33333%; }
  .row .col-push-9 {
    left: 37.5%; }
  .row .col-push-10 {
    left: 41.66667%; }
  .row .col-push-11 {
    left: 45.83333%; }
  .row .col-push-12 {
    left: 50%; }
  .row .col-push-13 {
    left: 54.16667%; }
  .row .col-push-14 {
    left: 58.33333%; }
  .row .col-push-15 {
    left: 62.5%; }
  .row .col-push-16 {
    left: 66.66667%; }
  .row .col-push-17 {
    left: 70.83333%; }
  .row .col-push-18 {
    left: 75%; }
  .row .col-push-19 {
    left: 79.16667%; }
  .row .col-push-20 {
    left: 83.33333%; }
  .row .col-push-21 {
    left: 87.5%; }
  .row .col-push-22 {
    left: 91.66667%; }
  .row .col-push-23 {
    left: 95.83333%; }
  .row .col-push-24 {
    left: 100%; }
  .row .col-push-0 {
    left: auto; }
  .row .col-offset-0 {
    margin-left: 0%; }
  .row .col-offset-1 {
    margin-left: 4.16667%; }
  .row .col-offset-2 {
    margin-left: 8.33333%; }
  .row .col-offset-3 {
    margin-left: 12.5%; }
  .row .col-offset-4 {
    margin-left: 16.66667%; }
  .row .col-offset-5 {
    margin-left: 20.83333%; }
  .row .col-offset-6 {
    margin-left: 25%; }
  .row .col-offset-7 {
    margin-left: 29.16667%; }
  .row .col-offset-8 {
    margin-left: 33.33333%; }
  .row .col-offset-9 {
    margin-left: 37.5%; }
  .row .col-offset-10 {
    margin-left: 41.66667%; }
  .row .col-offset-11 {
    margin-left: 45.83333%; }
  .row .col-offset-12 {
    margin-left: 50%; }
  .row .col-offset-13 {
    margin-left: 54.16667%; }
  .row .col-offset-14 {
    margin-left: 58.33333%; }
  .row .col-offset-15 {
    margin-left: 62.5%; }
  .row .col-offset-16 {
    margin-left: 66.66667%; }
  .row .col-offset-17 {
    margin-left: 70.83333%; }
  .row .col-offset-18 {
    margin-left: 75%; }
  .row .col-offset-19 {
    margin-left: 79.16667%; }
  .row .col-offset-20 {
    margin-left: 83.33333%; }
  .row .col-offset-21 {
    margin-left: 87.5%; }
  .row .col-offset-22 {
    margin-left: 91.66667%; }
  .row .col-offset-23 {
    margin-left: 95.83333%; }
  .row .col-offset-24 {
    margin-left: 100%; }

.width-1-1 {
  width: 100% !important; }

.width-1-2 {
  width: 50% !important; }

.width-1-3 {
  width: 33.33333% !important; }

.width-1-4 {
  width: 25% !important; }

.width-1-5 {
  width: 20% !important; }

.width-2-3 {
  width: 66.66667%; }

.color-text {
  color: #3d4145 !important; }

.color-text-secondary {
  color: #5f646e !important; }

.color-gray {
  color: #999 !important; }

.color-gray-light {
  color: #ccc !important; }

.color-white {
  color: #ffffff !important; }

.color-primary {
  color: #0894ec !important; }

.color-success {
  color: #4cd964 !important; }

.color-danger {
  color: #f6383a !important; }

.color-warning {
  color: #f60 !important; }

.txt-center {
  text-align: center; }

.txt-justify {
  text-align: justify; }

.txt-right {
  text-align: right; }

.txt-left {
  text-align: left; }

.txt-underline {
  text-decoration: underline; }

.txt-bold {
  font-weight: 600; }

.txt-normal {
  font-weight: 500; }

.txt-light {
  font-weight: 400; }

.txt-break {
  word-wrap: break-word; }

/*base*/
.main-size {
  width: 1305px;
  overflow: hidden; }

.abs-top {
  top: 0; }

.v-align-middle {
  vertical-align: middle !important; }

.btn {
  padding: 6px;
  display: inline-block;
  text-align: center; }

.btn-hollow-gray {
  background: transparent;
  border: 1px solid #e5e5e5; }

.top-20 {
  margin-top: 20px; }

.top-30 {
  margin-top: 30px; }

.top-15 {
  margin-top: 15px; }

.indent-30 {
  text-indent: 30px; }

.v-align-top {
  vertical-align: top; }

.top-10 {
  margin-top: 10px; }

.top-60 {
  margin-top: 60px; }

.left-30 {
  margin-left: 30px; }

.left-20 {
  margin-left: 20px; }

.left-15 {
  margin-left: 15px; }

.left-10 {
  margin-left: 10px; }

.left-60 {
  margin-left: 60px; }

.color-666 {
  color: #666; }

.color-999 {
  color: #999; }

.color-a7 {
  color: #a7a7a7; }

.color-black {
  color: #333; }

/*index.html*/
.header {
  position: relative; }
  .header .menu-wrap li {
    height: 53px;
    line-height: 53px;
    text-align: center;
    width: 116px; }
    .header .menu-wrap li .menu-item {
      color: white;
      font-size: 18px;
      height: 100%;
      display: inline-block; }
      .header .menu-wrap li .menu-item.active {
        border-bottom: 2px solid white; }
  .header .header-title {
    height: 70px;
    position: absolute;
    bottom: -70px;
    font-size: 60px;
    transition: transform 1s ease-out; }

.footer .sec-1 {
  background: #f8f8f8; }
  .footer .sec-1 .f-content {
    padding: 30px 0;
    padding-left: 115px; }

.footer .sec-2 {
  background: #e7e7e7;
  height: 40px;
  line-height: 40px;
  text-align: center; }

.main {
  padding: 70px 0; }

.id .row-1 .sec-1 {
  width: 422px;
  height: 393px;
  margin-right: 46px; }

.id .row-1 .sec-2 {
  width: 483px;
  height: 393px;
  margin-right: 33px; }
  .id .row-1 .sec-2 img {
    vertical-align: top; }
  .id .row-1 .sec-2 .news-list li {
    height: 74px; }
  .id .row-1 .sec-2 .news-list .list-left {
    width: 83px; }
  .id .row-1 .sec-2 .news-list .list-right {
    width: 400px; }

.id .row-1 .sec-3 {
  width: 321px; }

/*aboutUs.html*/
.au .row-1 .sec-1 {
  width: 422px;
  height: 393px;
  margin-right: 0; }

.au .row-1 .sec-2 {
  width: 760px;
  height: 440px;
  padding: 40px 35px;
  margin-right: 20px; }
  .au .row-1 .sec-2:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    overflow: visible; }

/*news.html*/
.ne .icon-up {
  color: #b4b4b4;
  transform: scale(1, 0.6) translateY(-7px); }

.ne .card {
  width: 300px;
  margin: 0 13px 45px;
  float: left;
  height: 472px; }
  .ne .card:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.1); }
  .ne .card .img-wrap {
    width: 100%;
    max-height: 196px;
    overflow: hidden; }
  .ne .card .content {
    padding: 30px 10px 44px; }

.cu .color-blue {
  color: #5481ff; }

.cu .cu-1, .cu .cu-2 {
  height: 478px; }

.cu .cu-1 {
  padding: 30px 70px 30px 30px;
  border-left: 2px solid #5481ff; }
